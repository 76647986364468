<script setup>
import { ref, onBeforeMount, defineProps } from "vue";
import { submit } from './FormWebPart.js'

const props = defineProps({
  data: {
    type: Object,
    default() {}
  },
  buttonText: {
    type: String,
    default: "Создать"
  },
  headerText: {
    type: String,
    default: "Заполните поля"
  }
});
const json = ref({});
const value = ref("");
const data = {};
// const loading = ref(true);
const lb = ref({loading: false})

onBeforeMount(() => {
    json.value = props.data
});

// Emit
function addData(vals) {
  value.value = vals ? vals.value : "no data";
  data["formHeader"]= {title: json.value.title, subtitle: json.value.subtitle, category: json.value.category}
  if (vals.id in data) {
    data[vals.id] = vals.value;
  } else {
    data[vals.id] = vals.value;
  }
  
  const component = json.value.columns.filter( elem => elem.props.id === vals.id )[0];

  if ("dependentComponent" in component.props) {
    const dependedComponent = component.props.dependentComponent;
    // data[dependedComponent] = ""; // Зачем это тут вообще было???
    json.value.columns.filter(
      elem => dependedComponent.includes(elem.props.id) // ? Возможно стоит подумать над преобразованием строки Зависимых компонентов в массив
    ).forEach(component => component.props.masterComponentValue = vals.value)
  }

  if ("dependentReqFileds" in component.props) {
    const dependedReqFileds = component.props.dependentReqFileds;
    json.value.columns.filter(
      elem => dependedReqFileds.includes(elem.props.id) // ? Возможно стоит подумать над преобразованием строки Зависимых компонентов в массив
    ).forEach(filed => {
      filed.props.required = (vals.value ? true : false);
      filed.props.hide = (vals.value ? false : true );
      } )
  }
console.log(JSON.stringify(data))
}

</script> 

<template>

    <div class="card mb-3 shadow rounded bg-transparent">
      <!-- <div class="card-header">
        <h3 class="show-font">{{ headerText }}</h3>
      </div> -->

      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="row g-0">
            <div class="col-md-9">
              <div class="card-body">
                <h5 class="card-title">{{ json.title }}</h5>
                <p class="card-text">{{ json.subtitle }}</p>
                <!-- <p class="card-text">
                  <small class="text-body-secondary">Обновленно 3 минуты назад</small>
                </p> -->
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item" v-if="json.img || json.description">
          <div class="row g-0">
            <div class="col-md-3" v-if="json.img">
              <img :src="require('@/assets/images/'+json.img)" class="card-img-top" alt="...">
            </div>
            <div class="col-md-9">
              <div class="card-body">
                <div v-html="json.description"></div>
              </div>
            </div>
          </div>
        </li>

        <li class="list-group-item">
          <form class="row g-3 needs-validation" novalidate>
            <template
              v-for="item in json.columns ? json.columns.sort(function(a,b){return a.order - b.order}) : null"
              :key="item.props.id"
            >
              <component :is="item.component" v-bind="item.props" @pushData="addData($event)"></component>
            </template>
            
            <div class="col-12">
              <div class="d-grid gap-2">
              <button type="submit" class="btn btn-success mt-4 d-grid gap-2" @click.stop.prevent="lb.loading=true;submit(data,lb)" :disabled="lb.loading">
                <div v-if="!lb.loading">
                  {{ buttonText }}
                </div>
                  <div class="d-flex justify-content-center" v-if="lb.loading">
                    <div class="spinner-border">
                  </div>
                </div>
              </button>
               </div>
            </div>
          </form>
        </li>
      </ul>
    </div>

</template>



<style>
li {
  background: transparent !important;
}
</style>
