<script setup>
import { defineProps, defineEmits, ref, onMounted, reactive } from "vue";
import { props as propsModel } from "./models/props.js";
import { sortBy, filterBy } from "./functions.js";
//Search part
import searchBox from "./utils/SearchWebPart";

const props = defineProps(propsModel);
const data = reactive({});
const filtredData = ref(data);
const emit = defineEmits(["pushData"]);

const buttonText = ref("");

onMounted(() => {
  emit("pushData", { id: props.id, value: null });
  if (props.options.constructor === Array) {
    data.value = props.options;
    if (props.filter.filterBy != "" && props.filter.value != "") {
      filterBy(data, props.filter);
    }

    if (props.sort != "") {
      sortBy(data, props.sort, "text");
    }

    // data.value = sortBy(props.options, "asc", "text");
    if (props.options.filter(e => e.selected === true).length > 0) {
      buttonText.value = props.options.filter(e => e.selected === true)[0].text;
      emit("pushData", { id: props.id, value: buttonText.value });
    }
  }
});

function selectElement(event) {
  buttonText.value = event.target.outerText;
  emit("pushData", { id: props.id, value: event.target.value });
}

function clearElement() {
  buttonText.value = null;
  emit("pushData", { id: props.id, value: "" });
}

function filterData(data) {
  filtredData.value = data;
}

function showSearchString() {
  if (props.searchbox) {
    if (data.value != undefined) {
      if (data.value.length != 0) {
        return true;
      }
    }
  }
  return false;
}
</script>

<template>
  <div class="mb-3" v-show="!props.hide">
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      :data-bs-html="true"
      style="color:#0d6efd"
      :data-bs-toggle=" tooltip === '' ? '' : 'tooltip'"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <div class="input-group" style="width: inherit;">
      <div class="dropdown d-grid gap-2 flex-fill" :id="id+'-dd'">
        <input
          :required="required"
          class="form-control form-select"
          v-model="buttonText"
          :value="buttonText"
          style="position: absolute; z-index: -1"
          :placeholder="placeholder"
          :disabled="readonly"
        >
        <button
          id="textButton"
          class="btn dropdown-toggle"
          :required="required"
          data-bs-display="static"
          type="text"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="width:auto; color:transparent"
        >{{ buttonText }}</button>
        <ul class="dropdown-menu container">
          <div class="row">
            <searchBox
              :data="data"
              :id="props.id"
              @pushData="filterData($event)"
              v-show="showSearchString()"
            />
          </div>
          <div v-show="!props.options.constructor === Array">
            <p class="ms-3 mb-0">Не заполнено свойтво options в json или заполненно не корректно</p>
          </div>
          <div class="row">
            <div class="col">
              <li v-for="item in filtredData" :key="item.value">
                <button
                  class="dropdown-item"
                  @click.prevent="selectElement"
                  :value="item.value"
                >{{ item.text }}</button>
              </li>
            </div>
          </div>
        </ul>
       
      </div>
       <button type="button" class="btn" @click.prevent="clearElement()" v-if="clearButton">
          <i class="bi bi-eraser-fill"></i>
        </button>
    </div>
    <div id class="form-text">{{ props.description }}</div>
  </div>
</template>

<style>
button#textButton {
  --bs-border-width: 1px;
  /* border: var(--bs-border-width) solid var(--bs-border-color); */
  border: transparent;
  text-align: left;
  border-radius: 7px;
}

button#textButton::after {
  border-top: 0em solid;
}
.tooltip {
  --bs-tooltip-max-width: auto;
}
.tooltip-inner {
  text-align: left;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
