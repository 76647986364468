<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  elementType: {
    type: String,
    default: "page"
  }
});
</script>

<template>
  <div class="d-flex justify-content-center align-items-center vh-75 user-select-none">
    <div v-if="props.elementType === 'page'">Страница не найдена</div>
    <div v-else-if="props.elementType === 'form'">
      <h1 class="display-1">Форма не найдена</h1>
      <h5 class>
        Похоже, запрошенная форма не существует.
      </h5>
      <h5 class>
        Вы можете вернуться на главную страницу
      </h5>

      <a
        role="button"
        @click="router.push('/')"
        style="--bs-icon-link-transform: translate3d(.425rem, 0, 0);"
        class="icon-link icon-link-hover"
      >
        <i class="bi bi-arrow-right-circle-fill pe-4 mb-2"></i>
        Перейти на главную страницу
      </a>
    </div>
  </div>
</template>

<style>
.vh-75 {
  height: 75vh;
}
a {
    text-decoration: auto;
}
</style>
