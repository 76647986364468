export const props = {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default() {
        return {
          API: "",
          textColumn: "",
          valueColumn: "",
          defaultValue: ""
        };
      }
    },
    columns: {
      type: Object,
      default() {
        return []
      }
    },
    filter: {
      type: Object,
      default() {
        return {
          filterBy: "",
          value: "",
        };
      }
    },
    type: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    preserveValue: {
      type: Boolean,
      default: false
    },
    sort: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Выберите значение"
    },
    value: {
      type: String,
      default: ""
    },
    dependentComponent: {
      type: String,
      default: ""
    },
    masterComponentValue: {
      type: String,
      default: undefined
    },
    searchbox: {
      type: Boolean,
      default: true
    },
    clearButton: {
      type: Boolean,
      default: false
    },
    maska: {
      type: String,
      default: null
    },
    maskaToken: {
      type: String,
      default: null
    },
    density: {
      type: String,
      default: 'default'
    },
    maxlength: {
      type: Number,
      default: 3000
    }
  }