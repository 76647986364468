<script setup>
import Tile from "./ListTile.vue";
import { ref, reactive, watch, defineProps } from "vue";
// import json from "../../../public/catalogElements.json";

// const tiles = ref(props.catalogElements)

const props = defineProps({
  showMoreStep: {
    type: Number,
    default: 3
  },
  catalogElements: {
    type: Object,
    default() {
      return [];
    }
  },
  categoryData: {
    type: Object,
    default() {
      return {};
    }
  }
});

const itemsAmount = ref(3);
const activeCategory = ref(false);
const shownElement = reactive([]);
shownElement.value = props.catalogElements.slice(0, itemsAmount.value);

watch(
  props,
  () => {
    activeCategory.value = true
    shownElement.value = props.catalogElements.slice(0, itemsAmount.value);
  },
  { deep: true }
);

watch(itemsAmount, nv => {
  if (nv <= props.catalogElements.length) {
    shownElement.value = props.catalogElements.slice(0, nv);
  } else {
    shownElement.value = props.catalogElements.slice(
      0,
      props.catalogElements.length
    );
  }
});
</script>

<template>
  <div class="shadow p-3 mb-5 rounded">
    <div class="row">
      <div class="col-12">
        <h3 class="display-6">{{props.categoryData.title}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>{{props.categoryData.description}}</h6>
      </div>
    </div>
    <div class="d-flex flex-wrap" v-if="props.catalogElements.length > 0">
      <Tile v-for="tile in shownElement.value" :key="tile" :tile="tile"/>
    </div>
    <div v-else-if="!activeCategory">
        <p class="text-body-secondary">Категория не выбрана. Выберете категорию из меню что бы заказать нужную услугу</p>
    </div>
    <div v-else>
       <p class="text-body-secondary">Данные не завезли в эту категорию..</p>
    </div>
    <div class="d-flex justify-content-center">
       <small> Показано {{ itemsAmount >= props.catalogElements.length ? props.catalogElements.length : itemsAmount }} из {{ props.catalogElements.length }} элементов</small>
    </div>
    <a
      :class="'btn btn-primary d-grid gap-2 mt-2' + (props.catalogElements.length < itemsAmount ? ' disabled' : ' notDisabled')"
      role="button"
      @click="itemsAmount+=props.showMoreStep"
    >Показать еще...</a>
  </div>
</template>
