<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import toastWP from './ToastWebPart'

const store = useStore()

const toasts = computed(() => store.getters.getToasts)


// const { getToasts } = useStore().getters;
// const toasts = computed(() => getToasts)

// const removeToast: (id) => store.commit('removeToast', id),

</script>

<template>
  <div class="toastContainer">
    <div v-for="toast in toasts"
      :key="toast">
    <toastWP :toast="toast" />
    </div>
  </div>
</template>

<style>
.toast{
  position: relative;
}
.toastContainer{
    position: fixed;
    bottom: 10px;
    right: 10px;
}
</style>