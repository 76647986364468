<script setup>
import { ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import PageNotFound from "../views/PageNotFound.vue";

const router = useRouter();
const route = useRoute();
const json = ref({});
const value = ref("");
const data = {};
const loading = ref(true);
onBeforeMount(() => {
  console.log("route.params.name");
  console.log(route.params.name);
  axios
    .get(`/${route.params.name}.json`)
    .then(resp => {
      console.log("resp");
      console.log(resp);
      json.value = resp.data;
      loading.value = false;
    })
    .catch(error => {
      loading.value = false;
      console.log(error);
    });
});
function addData(vals) {
  console.log("vals");
  console.log(vals);
  value.value = vals ? vals.value : "no data";
  if (vals.id in data) {
    data[vals.id] = vals.value;
  } else {
    data[vals.id] = vals.value;
  }
  console.log("data");
  console.log(data);
  const component = json.value.columns.filter(
    elem => elem.props.id === vals.id
  )[0]; // Компонент в котором обновили value
  if ("dependentComponent" in component.props) {
    // Смотрим есть ли завизимые компоненты
    const dependedComponent = component.props.dependentComponent; // Зависимый компонент
    data[dependedComponent] = "";
    json.value.columns.filter(
      elem => elem.props.id === dependedComponent
    )[0].props.masterComponentValue = vals.value; // Обновляем props в зависимом компоненте
  }
}
</script> 

<template>
  <div class="container">

    <div v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загружаю...</span>
      </div>
    </div>

    <div class="card mb-3 shadow rounded" v-else-if="Object.keys(json).length > 0">
      <div class="card-header">
        <a
          role="button"
          @click="router.push('/service-catalog')"
          style="--bs-icon-link-transform: translate3d(-.425rem, 0, 0); color: inherit"
          class="icon-link icon-link-hover"
        >
          <i class="bi bi-arrow-left-circle-fill pe-1 mb-2"></i>
          Вернуться в каталог услуг
        </a>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="row g-0">
            <div class="col-md-9">
              <div class="card-body">
                <h5 class="card-title">{{ json.title }}</h5>
                <p class="card-text">{{ json.subtitle }}</p>
                <p class="card-text">
                  <small class="text-body-secondary">Обновленно 3 минуты назад</small>
                </p>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row g-0">
            <div class="col-md-3">
              <img :src="require('@/assets/images/'+json.img)" class="card-img-top" alt="...">
            </div>
            <div class="col-md-9">
              <div class="card-body">
                <div v-html="json.description"></div>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <form class="row g-3">
            <template
              v-for="item in json.columns.sort(function(a,b){return a.order - b.order})"
              :key="item.props.id"
            >
              <component :is="item.component" v-bind="item.props" @pushData="addData($event)"></component>
            </template>
            <div class="col-12">
              <button
                disabled="true"
                class="btn btn-primary"
                type="submit"
                @click.prevent
              >Создать обращение</button>
            </div>
          </form>
        </li>
      </ul>
    </div>

    <div v-else>
      <PageNotFound elementType="form"/>
    </div>
    
  </div>
</template>



