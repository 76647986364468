<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";

import GroupColumn from "./ColumnMenuGroup.vue";

const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false
  },
  hideEmptyCategory: {
    type: Boolean,
    default: true
  },
  menuElement: {
    type: Object,
    default() {
      return {};
    }
  }
  
});
const emit = defineEmits(["pushCategory"]);

const collapsed = ref(props.collapsed);
console.log("menuElement")
console.log(props.menuElement)
onMounted(() => {

});

function countTiels(melem){
  let count = melem.tiles.length
  melem.children.forEach(elem => {
    count += countTiels(elem)
  })
  return count
}

function applyCategory(category) {
  console.log("click!");
  emit("pushCategory", category);
}
</script>

<template>
  <div v-show="countTiels(menuElement) || !hideEmptyCategory">
    <!-- [+] / [-] button  -->
    
    <button
      v-if="countTiels(menuElement) - menuElement.tiles.length > 0 || (menuElement.children.length > 0 && !hideEmptyCategory)"
      class="navbar-toggler pe-2"
      type="button"
      @click="collapsed = !collapsed"
    >
      <i v-if="collapsed" class="bi bi-dash-square"></i>
      <i v-else class="bi bi-plus-square"></i>
    </button>
    <!-- END BUTTON-->
    <a
      role="button"
      :class="{'navbar-brand': true, active: menuElement.active, 'user-select-none': true, 'text-wrap': true}"
      @click="applyCategory(menuElement.title)"
    >{{ menuElement.title}}
    <span class="badge text-bg-secondary">{{ countTiels(menuElement) }}</span>
    </a>
    <hr style="margin: 5px"/>
    <div
      v-if="props.menuElement.children.length > 0"
      :class="{ show: collapsed, collapse : true, 'ps-4': true}"
      :id="props.menuElement.title"
    >
      <ul class="btn-toggle-nav list-unstyled fw-normal small">
          <li>
          <GroupColumn
            v-for="elem in props.menuElement.children"
            :key="elem"
            :hideEmptyCategory="props.hideEmptyCategory"
            :menuElement="elem"
            @pushCategory="applyCategory($event)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.active{
  color: #0d6efd;
  font-weight: bold;
}
.navbar-brand:hover {
  color: #0d6efd;
}

</style>

