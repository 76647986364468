<script setup>
import { ref, onBeforeMount } from "vue";
import axios from "axios";
import PageNotFound from "../views/PageNotFound.vue";
import FormWebPart from "../components/FormWebPart.vue";
import { globals } from "@/main.js";

const json = ref({});
const loading = ref(true);

onBeforeMount(() => {
  axios
    .get(`newContract.json`)
    .then(resp => {
      json.value = resp.data;
      loading.value = false;
    })
    .catch(error => {
      loading.value = false;
      globals.$toast(
        "Ошибка",
        "Не возможно загрузить данные формы: " + error.message,
        5000,
        "error"
      );
    });
});
</script> 

<template>
  <div class="container col col-md-6 show-font">
    <div v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загружаю...</span>
      </div>
    </div>
    
    <FormWebPart v-else-if="Object.keys(json).length > 0" :data="json"/>

    <div v-else>
      <PageNotFound elementType="form"/>
    </div>
  </div>
</template>



<style>
li {
  background: transparent !important;
}
</style>
