<script setup>
import { defineProps } from "vue";
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
  tile: {
    type: Object,
    default() {
      return {};
    }
  }
});
// function clickTile(tile){
//     console.log("click tile")
//     console.log(tile)
// }
</script>
<template>
  <div class="card m-2 pe" style="min-width: 289px; width: 289px" role='button' @click="router.push(`/service-catalog/item/${props.tile.name}`)">
  <div class="card-header">
    <h5 class="user-select-none mb-0">{{ props.tile.title }}</h5>
  </div>
    <div class="card-body">
    
      <div class="row">
        <div class="col-3">
          <img src="@\assets\images\incident.png" class="img-fluid user-select-none" alt="...">
        </div>
        <div class="col-9">
          <p class="card-text user-select-none">{{ props.tile.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
