<template>
  <div class="offset-md-1 mt-5" v-if="ticket">
    <dl class="row">
      <dt class="col-sm-2 show-font">Номер обращения</dt>
      <dd class="col-sm-10">
        <span class="wback">{{ ticket.id }}</span>
      </dd>

      <dt class="col-sm-2 show-font">Инициатор</dt>
      <dd class="col-sm-10">
        <span class="wback">{{ ticket.initiator.name }}</span>
      </dd>

      <dt class="col-sm-2 show-font">Время создания</dt>
      <dd class="col-sm-10">
        <span class="wback">{{ formatDate(ticket.creation_timestamp) }}</span>
      </dd>

      <dt class="col-sm-2 show-font" v-if="ticket.reacted_time">Взят в работу</dt>
      <dd class="col-sm-10" v-if="ticket.reacted_time">
        <span class="wback">{{ formatDate(ticket.reacted_time) }}</span>
      </dd>

      <dt class="col-sm-2 show-font" v-if="ticket.completed_date">Завершён</dt>
      <dd class="col-sm-10" v-if="ticket.completed_date">
        <span class="wback">{{ formatDate(ticket.completed_date) }}</span>
      </dd>

      <dt class="col-sm-2 show-font">Статус обращения</dt>
      <dd class="col-sm-8">
        <span class="wback">В настоящий момент обращение типа "{{ ticket.category.name }}" находится в статусе "{{ ticket.status.name }}"</span>
      </dd>
      <dd class="col-sm-2"></dd>

      <dt class="col-sm-2 show-font">Краткое описание</dt>
      <dd class="col-sm-8">
        <span class="wback">{{ ticket.short_content }}</span>
      </dd>
      <dd class="col-sm-2"></dd>

      <dt class="col-sm-2 show-font">Полное описание</dt>
      <dd class="col-sm-7">
        <span class="wback" v-for="line in ticket.full_content.split('\n')" :key="line">{{ line }}<br /></span>
      </dd>
      <dd class="col-sm-2"></dd>

      <dt class="col-sm-2 show-font" v-if="ticket.solution">Решение</dt>
      <dd class="col-sm-7" v-if="ticket.solutuion">
        <span class="wback" v-for="line in ticket.solution.split('\n')" :key="line">{{ line }}<br /></span>
      </dd>
      <dd class="col-sm-2" v-if="ticket.solution"></dd>

      <dt class="col-sm-2 mt-2 show-font">Журнал</dt>
      <dd class="col-sm-10 mt-2">
        <dl class="row" v-for="comment in ticket.journals" :key="comment.id">
          <dt class="col-sm-2 show-font">{{ formatDate(comment.timestamp) }}</dt>
          <dd class="col-sm-7">
            <span class="wback">{{ comment.content }}</span>
          </dd>
        </dl>
      </dd>
    </dl>
  </div>
  <div class="offset-md-1 alert alert-danger d-flex align-items-center mt-5 col col-sm-5" role="alert" v-if="visible_not_allowed">
    <div>Данное обращение {{ id }} поднято не в рамках вашего контракта</div>
  </div>
  <div class="offset-md-1 alert alert-danger d-flex align-items-center mt-5 col col-sm-5" role="alert" v-if="visible_not_found">
    <div>Обращения с таким номером {{ id }} не существует</div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'

export default {
  name: "TicketView",
  props: ["id"],
  data: () => {
    return {
      ticket: null,
      visible_not_allowed: false,
      visible_not_found: false,
    }
  },
  created() {
    let token = localStorage.getItem("token")
    const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`

    axios
      .get(`${base_url}/tickets/ticket/${this.id}`,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
        })
      .then(resp => {
        this.ticket = resp.data
      })
      .catch(e=>{
        console.log("error from tickets")
        console.log(e)
        this.visible_not_found = true
        this.visible_not_allowed = true
      })

    // const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`
    // const fetch_property = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
    // fetch(`${base_url}/tickets/ticket/${this.id}`, fetch_property).then(async (response) => {
    //   if (response.ok) {
    //     const data = await response.json()
    //     this.ticket = data
    //   } else if (response.status === 404) {
    //     this.visible_not_found = true
    //   } else if (response.status === 405) {
    //     this.visible_not_allowed = true
    //   }
    // })
  },
  methods: {
    formatDate(inp_) {
      const date = new Date(inp_)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${day >= 10 ? day : "0" + day}.${month >= 10 ? month : "0" + month}.${year} ${hours >= 10 ? hours : "0" + hours}:${
        minutes >= 10 ? minutes : "0" + minutes
      }`
    },
  },
}
</script>
