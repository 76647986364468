
<script setup>
// TODO: {{ SingleLineText }}
// TODO: [Issue] Зависимое обязательное поле - не удаляется значение при выключение (возможно его стоит сохранять, но при выключенном свиче не использовать для формы, не передавать на форму)
// TODO: [Issue] Маска - не удаляется значение при выключение (возможно его стоит сохранять, но при выключенном свиче не использовать для формы, не передавать на форму)
// TODO: [Issue] Подсказка - не удаляется значение при выключение (возможно его стоит сохранять, но при выключенном свиче не использовать для формы, не передавать на форму)
// TODO: [DONE][Feature] Добавить возможность удаления столбцов. Добавить кнопку Х которая будет уталять поле
  // TODO: [FIXED][Issue] Удаляет последнее поле
// TODO: [Issue] Чекбох 'Скрытое поле' не работает на зависимых полях (Зависимое обязательное поле). Скрыть эту опцию на зависимых полях или сделать readonly
// TODO: [Issue] Чекбокс 'Обязательное поле' на зависимых полях активен но не имеет смыловой нагрузки. Сделать только для чтения и включеным.
// TODO: [Issue] 'Только для чтения' не делает поле серым.

// TODO

import { defineProps, defineEmits, reactive, ref, onMounted } from "vue";

const props = defineProps({
  column: {
    type: Object
  },
  json_: {
    type: Object
  }
});

const emit = defineEmits(["removeColumn"]);

const col = reactive(props.column);
const json = reactive(props.json_);
const chevroneIconDirectionIsOpen = ref(true);
const dependentReqFiledsSwitcher = ref(false);
const maskSwitcher = ref(false);
const filterSwitcher = ref(false);
const APISwitcher = ref(false);
const tooltipSwitcher = ref(false);
const dependentComponentSwitcher = ref(false);
const rules = {
  ColumnSingleLineText: {
    posible: ["placeholder", "dependentReqFileds", "maska"],
    req: []
  },
  ColumnDatePicker: {
    posible: ["placeholder", "dependentReqFileds", "maska"],
    req: []
  },
  ColumnMultiLineText: {
    posible: ["placeholder", "dependentReqFileds"],
    req: []
  },
  ColumnReferenceSelect: {
    posible: [
      "placeholder",
      "dependentReqFileds",
      "filter",
      "options",
      "sort",
      "searchbox",
      "clearButton",
      "dependentComponent"
    ],
    req: ["options"]
  },
  ColumnSelect: {
    posible: [
      "placeholder",
      "dependentReqFileds",
      "filter",
      "options",
      "sort",
      "searchbox",
      "clearButton",
      "dependentComponent"
    ],
    req: ["options"]
  }
};

onMounted(() => {
  const myCollapsible = document.getElementById("collapse" + col.props.id);
  myCollapsible.addEventListener("hidden.bs.collapse", event => {
    console.log(event);
    chevroneIconDirectionIsOpen.value = false;
  });
  myCollapsible.addEventListener("shown.bs.collapse", event => {
    console.log(event);
    chevroneIconDirectionIsOpen.value = true;
  });
});

function removeColumn(id){
  emit("removeColumn", {id: id });
}

function switchFilter(col) {
  if (!filterSwitcher.value) {
    col.props.filter = {
      filterBy: null,
      value: null
    };
    filterSwitcher.value = true;
  } else {
    filterSwitcher.value = false;
    delete col.filter;
  }
}

function switchAPI(col) {
  switch (col.component) {
    case "ColumnSelect":
      if (!APISwitcher.value) {
        col.props.options = [{
          text: "",
          value: ""
        }];
        APISwitcher.value = true;
      } else {
        APISwitcher.value = false;
        delete col.options;
      }
      break;
    case "ColumnReferenceSelect":
      if (!APISwitcher.value) {
        col.props.options = {
          API: "",
          textColumn: "",
          valueColumn: "",
          defaultValue: ""
        };
        APISwitcher.value = true;
      } else {
        APISwitcher.value = false;
        delete col.options;
      }
      break;
  }
}
function switchDependentComponent(col) {
  if (!dependentComponentSwitcher.value) {
    col.props.dependentComponent = "";
    dependentComponentSwitcher.value = true;
  } else {
    dependentComponentSwitcher.value = false;
    json.columns
      .filter(c => col.props.dependentComponent.includes(c.props.id))
      .forEach(c => delete c.props.masterComponentValue);
    delete col.props.dependentComponent;
  }
}

function selectDependentComponent(col, event) {
  if (!col.props.dependentComponent.length) {
    col.props.dependentComponent = event.target.outerText;
    json.columns.filter(
      c => c.props.id == event.target.outerText
    )[0].props.masterComponentValue = null;
  } else {
    let arr = col.props.dependentComponent.split(",");
    if (col.props.dependentComponent.includes(event.target.outerText)) {
      delete json.columns.filter(c => c.props.id == event.target.outerText)[0]
        .props.masterComponentValue;
      let index = arr.indexOf(event.target.outerText);
      arr.splice(index, 1);
    } else {
      json.columns.filter(
        c => c.props.id == event.target.outerText
      )[0].props.masterComponentValue = null;
      arr.push(event.target.outerText);
    }
    col.props.dependentComponent = arr.join(",");
  }
}
</script>

<template>
  <div>
    <!-- <div v-for="col in json.columns" :key="col"> -->
    <h5 class="pb-2">
      <span
        class="badge text-bg-secondary"
      >{{col.component.replace('Column','')}} поле с ID:{{col.props.id}}</span>
      <button
        class="btn text-bg-transparent"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#collapse'+col.props.id"
        aria-expanded="true"
        :aria-controls="'collapse'+col.props.id"
      >
        <i class="bi bi-chevron-up" v-if="chevroneIconDirectionIsOpen"></i>
        <i class="bi bi-chevron-down" v-else></i>
      </button>
      <button
        class="btn text-bg-transparent"
        type="button"
        style="float: right"
        @click="removeColumn(col.props.id)"
      >
      <i class="bi bi-trash" v-if="chevroneIconDirectionIsOpen"></i>
      </button>
    </h5>
    <div class="collapse show" :id="'collapse'+col.props.id">
      <div class="container">
        <div class="row">
          <div class="col-9">
            <div class="input-group mb-3">
              <div class="form-floating mb-2" style="width: 25%;">
                <input
                  class="form-control"
                  id="id"
                  placeholder="уникальный индификатор"
                  v-model="col.props.id"
                >
                <label for="id">уникальный индификатор *</label>
              </div>
              <div class="form-floating mb-2">
                <input class="form-control" id="order" placeholder v-model="col.order">
                <label for="id">Порядковый номер поля *</label>
              </div>
            </div>
          </div>
          <!-- Правая сторорна пустая -->
        </div>
        <div class="row">
          <div class="col-9">
            <div class="form-floating mb-2">
              <input class="form-control" id="label" placeholder="Mетка" v-model="col.props.label">
              <label for="label">Mетка</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="required"
                v-model="col.props.required"
              >
              <label class="form-check-label" for="required">Обязательное поле</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <div class="input-group">
              <div class="form-floating mb-2" style="width: 25%;">
                <input
                  class="form-control"
                  id="placeholder"
                  placeholder="Заполнитель"
                  v-model="col.props.placeholder"
                >
                <label for="img">Заполнитель</label>
              </div>
              <div class="form-floating" v-if="rules[col.component].posible.includes('sort')">
                <select class="form-select" aria-label id="sort" v-model="col.props.sort">
                  <option selected :value="undefined">По умолчанию</option>
                  <option value="asc">По возрастанию</option>
                  <option value="desc">По убыванию</option>
                </select>
                <label for="sort">Сортировка</label>
              </div>
            </div>
          </div>
          <div class="col-3" v-if="rules[col.component].posible.includes('searchbox')">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="searchbox"
                checked
                v-model="col.props.searchbox"
              >
              <label class="form-check-label" for="searchbox">Отключить строку поиска</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <div class="input-group mb-3">
              <div class="form-floating mb-2">
                <input
                  class="form-control"
                  id="defaultValue"
                  placeholder="Заполнитель"
                  v-model="col.props.value"
                >
                <label for="defaultValue">Значение по умолчанию</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <div class="form-floating mb-2">
              <textarea
                class="form-control"
                id="description"
                placeholder="Заполнитель"
                v-model="col.props.description"
                style="height: 100px"
              ></textarea>
              <label for="description">Подробное описание</label>
            </div>
            <span
              class="badge rounded-pill text-bg-info"
              v-if="col.props.masterComponentValue === null"
            >
              Зависит от {{ json.columns
              .filter(c => Object.keys(c.props).includes('dependentComponent'))
              .filter(c=>c.props.dependentComponent.includes(col.props.id))
              .map(e => e.props.id)
              .join(", ") }}
            </span>
          </div>
          <div class="col-3">
            <div class="row" v-if="rules[col.component].posible.includes('clearButton')">
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="clearButton"
                    v-model="col.props.clearButton"
                  >
                  <label class="form-check-label" for="clearButton">Кнопка отчистки</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="hideFiled"
                    v-model="col.props.hide"
                  >
                  <label class="form-check-label" for="hideFiled">Скрытое поле</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="readonlyField"
                    v-model="col.props.readonly"
                  >
                  <label class="form-check-label" for="readonlyField">Только для чтения</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr">

        <div class="row">
          <div class="col-9" v-if="dependentReqFiledsSwitcher">
            <div class="form-floating mb-2">
              <div class="form-floating">
                <select
                  class="form-select"
                  aria-label
                  id="dependentReqFileds"
                  v-model="col.props.dependentReqFileds"
                >
                  <option
                    v-for="opt in json.columns.filter(c => c.props.id != col.props.id)"
                    :key="opt"
                    :value="opt.props.id"
                  >{{ opt.props.id }}</option>
                </select>
                <label for="dependentReqFileds">Зависимое обязательное поле</label>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check form-switch pt-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="dependentReqFiledsSwitcher"
                v-model="dependentReqFiledsSwitcher"
              >
              <label
                class="form-check-label"
                for="dependentReqFiledsSwitcher"
              >Зависимое обязательное поле</label>
            </div>
          </div>
        </div>

        <hr class="hr">

        <div class="row" v-if="rules[col.component].posible.includes('maska')">
          <div class="col-9" v-if="maskSwitcher">
            <div class="form-floating mb-2">
              <input
                class="form-control"
                id="maska"
                placeholder="+7 (###) ###-##-##"
                v-model="col.props.maska"
              >
              <label for="maska">Маска</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check form-switch pt-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="maskSwitcher"
                v-model="maskSwitcher"
              >
              <label class="form-check-label" for="maskSwitcher">Маска</label>
            </div>
          </div>
        </div>

        <hr class="hr" v-if="rules[col.component].posible.includes('maska')">

        <div class="row" v-if="rules[col.component].posible.includes('filter')">
          <div class="col-9" v-if="filterSwitcher">
            <div class="row">
              <div class="col">
                <div class="form-floating mb-2">
                  <input
                    class="form-control"
                    id="filterBy"
                    placeholder="Внутренне название поля для фильтрации"
                    v-model="col.props.filter.filterBy"
                  >
                  <label for="filterBy">Внутренне название поля для фильтрации</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-floating mb-2">
                  <input
                    class="form-control"
                    id="filterValue"
                    placeholder="Значение по кторому фильтровать"
                    v-model="col.props.filter.value"
                  >
                  <label for="filterValue">Значение по кторому фильтровать</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check form-switch pt-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="filterSwitcher"
                :value="filterSwitcher"
                @change.prevent="switchFilter(col)"
              >
              <label class="form-check-label" for="filterSwitcher">Фильтр</label>
            </div>
          </div>
        </div>

        <hr class="hr" v-if="rules[col.component].posible.includes('filter')">

        <div class="row" v-if="rules[col.component].posible.includes('options')">
          <div class="col-9" v-if="APISwitcher || rules[col.component].req.includes('options')">
            <div id="ColumnReferenceSelectOptions" v-if="col.component === 'ColumnReferenceSelect'">
              <div class="row">
                <div class="col">
                  <div class="form-floating mb-2">
                    <input
                      class="form-control"
                      id="api_url"
                      placeholder="Адрес запроса"
                      v-model="col.props.options.API"
                    >
                    <label for="api_url">Адрес запроса</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-floating mb-2">
                    <input
                      class="form-control"
                      id="api_textColumn"
                      placeholder="Внутреннее поле вывода"
                      v-model="col.props.options.textColumn"
                    >
                    <label for="api_textColumn">Внутреннее поле вывода</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-floating mb-2">
                    <input
                      class="form-control"
                      id="api_valueColumn"
                      placeholder="Внутреннее поле значения"
                      v-model="col.props.options.valueColumn"
                    >
                    <label for="api_valueColumn">Внутреннее поле значения</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-floating mb-2">
                    <input
                      class="form-control"
                      id="api_defaultValue"
                      placeholder="Значение по умолчанию"
                      v-model="col.props.options.defaultValue"
                    >
                    <label for="api_defaultValue">Значение по умолчанию</label>
                  </div>
                </div>
              </div>
            </div>
            <div id="ColumnSelectOptions" v-if="col.component === 'ColumnSelect'">
              <div class="input-group mb-3" v-for="option in col.props.options" :key="option">
                <div class="row">
                  <div class="col">
                    <div class="form-floating mb-2">
                      <input
                        class="form-control"
                        id="options_key"
                        placeholder="Внутреннее поле вывода"
                        v-model="option.text"
                      >
                      <label for="options_key">Ключ</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-floating mb-2">
                      <input
                        class="form-control"
                        id="options_value"
                        placeholder="Внутреннее поле вывода"
                        v-model="option.value"
                      >
                      <label for="options_value">Значение</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check form-switch pt-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="APISwitcher"
                :value="APISwitcher"
                :checked="rules[col.component].req.includes('options')"
                :disabled="rules[col.component].req.includes('options')"
                @change.prevent="switchAPI(col)"
              >
              <label class="form-check-label" for="APISwitcher">Опции запроса</label>
            </div>
          </div>
        </div>

        <hr class="hr" v-if="rules[col.component].posible.includes('options')">

        <div class="row">
          <div class="col-9" v-if="tooltipSwitcher">
            <div class="form-floating mb-2">
              <textarea
                class="form-control"
                id="tooltip"
                placeholder="Текс подсказки (HTML)"
                v-model="col.props.tooltip"
                style="height: 200px"
              ></textarea>
              <label for="tooltip">Подсказка</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check form-switch pt-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="tooltipSwitcher"
                v-model="tooltipSwitcher"
              >
              <label class="form-check-label" for="tooltipSwitcher">Подсказка</label>
            </div>
          </div>
        </div>

        <hr class="hr">

        <div class="row" v-if="rules[col.component].posible.includes('dependentComponent')">
          <div class="col-9" v-if="dependentComponentSwitcher">
            <div class="dropdown d-grid gap-2" :id="col.props.id+'-dd'">
              <input
                class="form-control"
                v-model="col.props.dependentComponent"
                style="position: absolute; z-index: -1; color: white"
              >
              <div
                id="textButton"
                class="btn dropdown-toggle form-select d-flex"
                data-bs-display="static"
                type="text"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <div
                  v-for="v in col.props.dependentComponent.split(',')"
                  :key="v"
                  class="badge text-bg-primary px-2 me-1 d-flex align-items-center"
                >{{ v }}</div>
                <div
                  v-if="col.props.dependentComponent.length===0"
                  class="text-secondary"
                >Выберете поле</div>
              </div>
              <ul class="dropdown-menu container">
                <div class="row">
                  <div class="col">
                    <li
                      v-for="column in json.columns.filter(c => c.props.id != col.props.id)"
                      :key="column"
                    >
                      <button
                        class="dropdown-item"
                        :class="col.props.dependentComponent.includes(column.props.id) ? 'dropdown-item active':'dropdown-item' "
                        @click.prevent="selectDependentComponent(col,$event)"
                        :value="column.props.id"
                      >{{ column.props.id }}</button>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check form-switch pt-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="dependentComponentSwitcher"
                :value="dependentComponentSwitcher"
                @change.prevent="switchDependentComponent(col)"
              >
              <label class="form-check-label" for="dependentComponentSwitcher">Зависимые поля</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
