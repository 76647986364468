<script setup>
import { ref, defineEmits, defineProps, computed, onMounted, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment";
import { props as propsModel } from "./models/props.js";
import "@vuepic/vue-datepicker/dist/main.css";

const props = defineProps(propsModel);
const emit = defineEmits(["pushData"]);
const datetime = ref(moment(props.value, "DD.MM.YYYY HH:mm"));

// const swiper = ref(null); // $ref in template
const swiperComputed = computed(() => {
  if (datetime.value === null || datetime.value._isValid === false) {
    return "form-control datepicker invalid";
  } else {
    return "form-control datepicker valid";
  }
});

watch(datetime, () => {
  let date = {id: props.id, value: (datetime.value ? moment(datetime.value).format('DD.MM.YYYY HH:mm') : '')}
  emit('pushData', date ) 
});

onMounted(() => {
  let datatimediv = document.querySelector(".datepicker");
  let datetimeInput = datatimediv.querySelectorAll("input");
  datetimeInput.forEach(elem => elem.classList.add("form-control"));
  // datetimeInput[0].classList.add('was-validated')
});

function density() {
  let densityClass = "mb-3";
  switch (props.density) {
    case "compact":
      densityClass = "m-0";
      break;
    case "comfortable":
      densityClass = "mb-1";
      break;
    default:
      break;
  }
  return densityClass;
}

function changeDate(date){
  console.log("fucntion date")
  console.log(date)
  emit('pushData', {date}) 
}
</script>

<template>
  <div :class="density()">
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-toggle=" tooltip === '' ? '' : tooltip"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <VueDatePicker
      v-model="datetime"
      :min-date="props.masterComponentValue ? moment(props.masterComponentValue, 'DD.MM.YYYY HH:mm').format('MM.DD.YYYY HH:mm') : null"
      :required="required"
      :disabled="readonly"
      locale="ru"
      cancelText="Отмена"
      selectText="Выбрать"
      format="dd/MM/yyyy HH:mm"
      :class="swiperComputed"
      :placeholder="placeholder"
      @change="changeDate({id: props.id, value: (datetime ? moment(datetime).format('DD.MM.YYYY HH:mm') : '')})"
    >
      <template #dp-input="{value}">
        <input
          type="text"
          class="form-control dp__pointer dp__input_readonly dp__input dp__input_icon_pad dp__input_reg"
          :value="value"
          :required="required"
          style="border-color: transparent"
          :placeholder="placeholder"
        >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="currentColor"
            aria-hidden="true"
            class="dp__icon dp__input_icon dp__input_icons"
          >
            <path
              d="M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z"
            ></path>
            <path
              d="M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
            ></path>
            <path
              d="M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
            ></path>
            <path
              d="M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
            ></path>
          </svg>
        </div>
      </template>
    </VueDatePicker>
    <div class="form-text">{{ props.description }}</div>
  </div>
</template>

<style scoped>
.datepicker input::placeholder {
  color: var(--bs-body-color);
}
.dp__input {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.dp__input::placeholder {
  opacity: 0.8;
}
.was-validated .form-control.invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-control.valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}
</style>

