<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref } from "vue";
const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  value: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: "checkbox"
  },
  id: {
    type: String,
    default: ""
  },
  description: {
    type: String,
    default: ""
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: String,
    default: ""
  }
});

const inputValue = ref(props.value);
const emit = defineEmits(["pushData"]);
</script>
<template>
  <div class="form-check mb-3">
    <input
      :data-bs-toggle=" tooltip === '' ? '' : tooltip"
      data-bs-placement="top"
      :title="tooltip"
      :required="required"
      :disabled="readonly"
      :type="type"
      class="form-check-input"
      :id="id"
      aria-describedby="emailHelp"
      v-model="inputValue"
      :value="inputValue"
      :change="emit('pushData', {id: id, value: inputValue})"
    >
    <label :for="id" class="form-check-label">{{ props.label }}</label> <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-toggle=" tooltip === '' ? '' : tooltip"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <div class="form-text">{{ props.description }}</div>
  </div>
</template>
