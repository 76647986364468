<script setup>
import axios from "axios"
import { useRouter } from "vue-router"
import { ref } from "vue"
import { globals } from "@/main.js";

const router = useRouter()
const username = ref("")
const password = ref("")

const login = function () {

  const headers = {
    "Content-type": "application/x-www-form-urlencoded",
  }

  let params = new URLSearchParams()
  params.append("username", username.value)
  params.append("password", password.value)

  axios
    .post(`${process.env.VUE_APP_BASE_API}/api/v1/auth/token`, params, {
      headers: headers,
    })
    .then((resp) => {
      localStorage.setItem("token", resp.data["access_token"])
      router.push("/")
    })
    .catch((e) => {
      globals.$toast("Ошибка", e.message, 5000, 'error')
    })
}
</script>

<template>
  <div class="offset-md-1 col-md-4 show-font">
    <form @submit.prevent="login" name="login_user_form">
      <div>
        <label for="validationTooltip01" class="form-label">Имя пользователя</label>
        <input type="text" class="form-control" id="validationTooltip01" placeholder="Например: ivanov.ii" v-model="username" />
        <div class="invalid-tooltip">Введите имя пользователя</div>
      </div>
      <div>
        <label for="validationTooltip02" class="form-label mt-4">Пароль</label>
        <input type="password" class="form-control" id="validationTooltip02" v-model="password" />
        <div class="invalid-tooltip">Введите пароль</div>
      </div>
      <div class="alert alert-danger d-flex align-items-center mt-3" role="alert" v-if="visible_creds">
        <div>Неверное имя или пароль</div>
      </div>
      <div class="alert alert-danger d-flex align-items-center mt-3" role="alert" v-if="visible_empty">
        <div>Введите имя и пароль</div>
      </div>
      <div class="col-12 mt-4">
        <button class="btn btn-success" type="submit" @click.prevent="login">Войти</button>
      </div>
    </form>
  </div>
</template>
