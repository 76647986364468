<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  value: {
    type: String,
    default: ""
  },
  placeholder: {
    type: String,
    default: "Выберите фаил архива"
  },
  type: {
    type: String,
    default: "text"
  },
  id: {
    type: String,
    default: ""
  },
  description: {
    type: String,
    default: ""
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: String,
    default: ""
  },
  maska: {
    type: String,
    default: null
  }
});

// const inputValue = ref(props.value);
const emit = defineEmits(["pushData"]);
const file = ref(null)
function chooseFile(event){
  console.log(event)
  file.value = event.target.files[0]
  emit('pushData', {id: props.id, value: event.target.files[0]})
}
</script>

<template>
  <div class="input-group mt-4">
          <span class="input-group-text show-font"><i class="bi bi-folder2-open"></i></span>
          <label class="form-control">
            {{ file ? file.name : placeholder }}
            <input
              @change="chooseFile"
              type="file"
              class="invisible"
              accept=".7z, .ace, .arj, .bin,
      .bin, .cab, .cab, .cbr, .deb, .gz, .gzip, .one, .pak, .pkg, .ppt, .rar, .rpm, .sib, .sis, .sisx, .sit, .sitx, .spl, .spl, .tar,
      .tar-gz, .tgz, .xar, .zip, .zip, .zipx"
          /></label>
        </div>
</template>
