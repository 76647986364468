<template>
  <!-- <a class="main btn btn-info" href="/">На главную</a> -->
  <a
    class="main btn btn-info"
    @click.prevent="this.$router.push('/')"
    @click.middle="this.$router.push('/')"
  >На главную</a>
</template>

<script>
export default {
  name: "ButtonMain"
};
</script>

<style>
.main {
  position: fixed;
  left: 30px;
  bottom: 20px;
  opacity: 1;
  padding: 10px;
  color: #fff;
  background-color: #1ea4bf;
}
</style>
