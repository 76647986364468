<script setup>
import { ref, reactive, onMounted, watch } from "vue";

import Menu from "../components/serviceCatalog/MenuWebpart.vue";
import TileList from "../components/serviceCatalog/ListTilesWebpart.vue";

// TODO: Temporary data files. Need to be switched to axios request to backend
import json_menu_category from "../../public/category.json";
import json_catalog_elements from "../../public/catalogElements.json";

const category = ref(json_menu_category.filter(elem=>elem.active));
const catalogElements = ref(json_catalog_elements.filter(elem=>elem.active));
// TODO ----------------------------------------------------------------------
const unflattenArray = ref([]);
// const activeElement = ref(null);
const categoryChain = ref([]);
const filteredCatalogElements = reactive([]);
const activeElemObj = ref({});
const search = ref("");

watch(search, () => {
  filterElementsBySearch();
});
watch(categoryChain, () => {
  filterElementsByCategory();
});
onMounted(() => {
  category.value = json_menu_category.filter(elem=>elem.active)
  unflatten(category.value);
});

function changeActiveElement(elems, activeElem) {
  elems.forEach(elem => {
    if (elem.active && elem.title != activeElem) {
      elem.active = false;
    }
    if (elem.title === activeElem) {
      elem.active = true;
    }
    changeActiveElement(elem.children, activeElem);
  });
}

function unflatten(arr) {
  var mappedArr = {},
    arrElem,
    mappedElem;
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = {...arr[i]};
    mappedArr[arrElem.title] = arrElem;
    mappedArr[arrElem.title]["children"] = [];
    mappedArr[arrElem.title]["tiles"] = catalogElements.value.filter(
      elem => elem.category === arrElem.title
    );
    mappedArr[arrElem.title]["show"] = !arrElem.active;
    mappedArr[arrElem.title]["active"] = false;
  }

  for (var title in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, title)) {
      mappedElem = mappedArr[title];
      if (mappedElem.parent) {
        mappedArr[mappedElem["parent"]]["children"].push(mappedElem);
      } else {
        unflattenArray.value.push(mappedElem);
      }
    }
  }
}
function filterElementsBySearch() {
  filteredCatalogElements.value = catalogElements.value.filter(elem => {
    // ? Поиск по заголовку
    let findInTitle = elem.title.toLowerCase().includes(search.value.toLowerCase());
    // ? Поиск по описанию
    let findInDescription = elem.description.toLowerCase().includes(search.value.toLowerCase());
    return findInTitle || findInDescription
  });
}
function filterElementsByCategory() {
  filteredCatalogElements.value = catalogElements.value.filter(elem => {
    if (categoryChain.value.length > 0) {
      return categoryChain.value.includes(elem.category);
    } else {
      return elem;
    }
  });
}

function getElementByCategory(elems, category) {
  // ? elems должно быть unflattenArray
  for (let i = 0; elems.length > i; i++) {
    if (elems[i].title === category) {
      activeElemObj.value = elems[i];
      break;
    } else {
      if (elems[i].children.length > 0) {
        getElementByCategory(elems[i].children, category);
      }
    }
  }
}

function buildCategoryChain(elems, category) {
  getElementByCategory(unflattenArray.value, category);
  categoryChain.value.push(activeElemObj.value.title);

  function pushChildrenElements(elem) {
    elem.forEach(child => {
      categoryChain.value.push(child.title);
      if (child.children.length > 0) {
        pushChildrenElements(child.children);
      }
    });
  }
  if (activeElemObj.value.children.length > 0) {
    pushChildrenElements(activeElemObj.value.children);
  }
}

function applyCategory(category) {
  console.log("-- emit category --");
  console.log(category);
  categoryChain.value = [];
  buildCategoryChain(unflattenArray.value, category);
  changeActiveElement(unflattenArray.value, category);
}
</script> 

<template>
  <div class="container">
    <div class="position-absolute top-0 end-50 search">
      <input class="form-control" placeholder="Поиск..." v-model="search">
    </div>
    <div class="row">
      <div class="col col-3">
        <Menu
          @pushCategory="applyCategory($event)"
          :menuElements="unflattenArray"
          :hideEmptyCategory="true"
        />
      </div>
      <div class="col col-9">
        <TileList
          :catalogElements="filteredCatalogElements.value"
          :categoryData="{ title: activeElemObj.title, description: activeElemObj.description }"
        />
      </div>
    </div>
  </div>
</template>
<style>
.search {
  right: 17% !important;
  top: 3% !important;
}
</style>



