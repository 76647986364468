<script setup>
import { ref } from "vue";

import PreviewWebPart from "../components/FormWebPart.vue";
import BuilderWebPart from "../components/BuilderWebPart.vue";

const json = ref({});
// json.value.columns = [];
// json.value.columns.push({
//   component: "ColumnReferenceSelect",
//   order: 4,
//   props: {
//     searchbox: false,
//     id: "vendor",
//     label: "Производитель",
//     required: true,
//     options: {
//       API: "/tickets/category_groups?id=${user_id}",
//       textColumn: "name",
//       valueColumn: "id",
//       defaultValue: null
//     },
//     dependentComponent: "category_id",
//     masterComponentValue: null
//   }
// });
function checkForm(val) {
  Object.keys(val.value).forEach(v=>{
      if(Array.isArray(val.value[v])){
          json.value[v] = JSON.parse(JSON.stringify(val.value[v]))
      }
      else{
        json.value[v] = val.value[v]
      }
  })
//   json.value = val.value;
}
</script> 

<template>
  <div class="container">
    <div class="row">
      <div class="col col-md-6 show-font">
        <PreviewWebPart :data="json" buttonText="Сохранить форму" headerText="Предпросмотр"/>
      </div>
      <div class="col col-md-6 show-font">
        <BuilderWebPart @checkForm="checkForm($event)"/>
      </div>
    </div>
  </div>
</template>



<style>
li {
  background: transparent !important;
}
</style>
