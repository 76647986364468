import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'

import ColumnSingleLineText from './components/serviceCatalogFormConstructor/Columns/ColumnSingleLineText'
import ColumnMultiLineText from './components/serviceCatalogFormConstructor/Columns/ColumnMultiLineText'
import ColumnCheckbox from './components/serviceCatalogFormConstructor/Columns/ColumnCheckbox'
import ColumnMultiCheckbox from './components/serviceCatalogFormConstructor/Columns/ColumnMultiCheckbox'
import ColumnDatePicker from './components/serviceCatalogFormConstructor/Columns/ColumnDatePicker'
import ColumnRadioButton from './components/serviceCatalogFormConstructor/Columns/ColumnRadioButton'
import ColumnSelect from './components/serviceCatalogFormConstructor/Columns/ColumnSelect'
import ColumnReferenceSelect from './components/serviceCatalogFormConstructor/Columns/ColumnReferenceSelect'
import ColumnReferenceMultiSelect from './components/serviceCatalogFormConstructor/Columns/ColumnReferenceMultiSelect'
import ColumnAttachments from './components/serviceCatalogFormConstructor/Columns/ColumnAttachments'
// import ColumnThreeInRow from './components/serviceCatalogFormConstructor/Columns/ColumnThreeInRow'
import ColumnGroupCheckbox from './components/serviceCatalogFormConstructor/Columns/ColumnGroupCheckbox'


const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)
app.use(store)
app.mount('#app')
app.component('ColumnSingleLineText', ColumnSingleLineText)
app.component('ColumnMultiLineText', ColumnMultiLineText)
app.component('ColumnCheckbox', ColumnCheckbox)
app.component('ColumnMultiCheckbox', ColumnMultiCheckbox)
app.component('ColumnDatePicker', ColumnDatePicker)
app.component('ColumnRadioButton', ColumnRadioButton)
app.component('ColumnSelect', ColumnSelect)
app.component('ColumnReferenceSelect', ColumnReferenceSelect)
app.component('ColumnReferenceMultiSelect', ColumnReferenceMultiSelect)
app.component('ColumnAttachments', ColumnAttachments)
// app.component('ColumnThreeInRow', ColumnThreeInRow)
app.component('ColumnGroupCheckbox', ColumnGroupCheckbox)

app.config.globalProperties.$toast = (title, message, timer, color) => {
    store.dispatch('showToast',{title: title,message: message, timer: timer, color:color})
 }
 const globals = app.config.globalProperties
 export { globals }
 
import "bootstrap/dist/js/bootstrap.js"
