<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref, watch, onMounted } from "vue";
import { vMaska } from "maska"
import { props as propsModel } from './models/props.js'
import bootstrap from "bootstrap/dist/js/bootstrap.js";

const props = defineProps(propsModel);
const inputValue = ref(props.value);
const emit = defineEmits(["pushData"]);

onMounted(() => {
  // Initializing ToolTips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList];
  tooltipList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
})

watch(props, () => {
  if(props.hide && !props.preserveValue){
    emit('pushData', {id: props.id, value: ""})
    inputValue.value = ""
  }
inputValue.value = props.value
  // if (newValue != "" && newValue != null && newValue != undefined) {
  //   if (newValue.masterComponentValue === "") {
  //     data.value = {};
  //   } else {
  //     fetchData(newValue.masterComponentValue);
  //   }
  // }
});
function density(){
  let densityClass = "mb-3"
  switch(props.density){
    case "compact": 
      densityClass = "m-0"
      break
    case "comfortable": 
      densityClass = "mb-1"
      break
    default:
      break
  }
  return densityClass
}
</script>

<template>
<transition name="fade">
  <div :class="density()" v-show="!props.hide">
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-html="true"
      :data-bs-toggle=" tooltip === '' ? '' : 'tooltip'"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <input
      v-maska
      :data-maska="maska"
      :data-maska-tokens="maskaToken"
      :placeholder="placeholder"
      :required="required"
      :disabled="readonly"
      :type="type"
      class="form-control"
      :id="id"
      aria-describedby="emailHelp"
      v-model="inputValue"
      :value="inputValue"
      :change="emit('pushData', {id: id, value: inputValue})"
    >
    <div id class="form-text">{{ props.description }}</div>
  </div>
  </transition>
</template>
<style>
.fade-enter-active {
  transition: all 1s ease;
}
.fade-enter-from {
  transform: translateY(-20px);
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave-active {
  transition: all 1s ease;
}
.fade-leave-from {
  transform: translateY(20px);
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}


</style>
