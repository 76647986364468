<script setup>
import { defineEmits, ref } from "vue";
import commonColumn from "./serviceCatalogFormConstructor/BuilderColumns/commonColumn.vue";
const emit = defineEmits(["checkForm"]);
const json = ref({
  title: "",
  subtitle: "",
  img: "",
  description: "",
  columns: []
});

function addColumn(type) {
  let column = {
    component: type,
    order: json.value.columns.length + 1,
    props: {
      id: new Date().getMilliseconds().toString()
    }
  };

  if (type === "ColumnReferenceSelect") {
    column.props.options = {
      API: "",
      textColumn: "",
      valueColumn: "",
      defaultValue: ""
    };
  }

  if (type === "ColumnSelect") {
    column.props.options = [{
      text: "",
      value: ""
    }];
  }

  json.value.columns.push(column);
}
function checkForm() {
  console.log(json)
  emit("checkForm", json);
}
</script>

<template>
  <div class="container builder">{{json}}
    <div class="card shadow rounded bg-transparent">
      <div class="header">
        <h5 class="card-header">Шапка формы</h5>
        <div class="card-body">
          <div class="form-floating mb-3">
            <input class="form-control" id="title" placeholder="Заголовок" v-model="json.title">
            <label for="title">Заголовок</label>
          </div>
          <div class="form-floating mb-3">
            <input
              class="form-control"
              id="subtitle"
              placeholder="Подзаголовок"
              v-model="json.subtitle"
            >
            <label for="subtitle">Подзаголовок</label>
          </div>
          <div class="form-floating mb-3">
            <input class="form-control" id="img" placeholder="Имя картинки" v-model="json.img">
            <label for="img">Имя картинки</label>
          </div>
          <div class="form-floating mb-3">
            <textarea
              class="form-control"
              id="header_description"
              placeholder="Описание"
              v-model="json.description"
              style="height: 300px"
            ></textarea>
            <label for="header_description">Описание</label>
          </div>
        </div>
      </div>

      <div class="columns">
        <h5 class="card-header">Поля</h5>
        <div class="card-body">
          <div v-for="col in json.columns" :key="col">
            <commonColumn :column="col" :json_="json"/>
          </div>
        </div>

        <div class="dropdown mt-4 d-grid gap-2">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >Добавить поле</button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                @click="addColumn('ColumnSingleLineText')"
              >Single Line of Text</a>
            </li>
            <li>
              <a class="dropdown-item" @click="addColumn('ColumnSelect')">Select</a>
            </li>
            <li>
              <a class="dropdown-item" @click="addColumn('ColumnReferenceSelect')">Reference Select</a>
            </li>
            <li>
              <a class="dropdown-item" @click="addColumn('ColumnMultiLineText')">MultiLine Text</a>
            </li>
            <li>
              <a class="dropdown-item" @click="addColumn('ColumnDatePicker')">Date Picker</a>
            </li>
          </ul>
        </div>
      </div>

      <button class="btn btn-success d-grid gap-2" @click="checkForm()">Check form</button>
    </div>
  </div>
</template>
<style>
.builder .form-floating > label {
  top: -8px;
  font-size: 0.8rem;
  padding: 0.9rem 0.75rem;
  overflow: visible;
}
.builder .form-floating > .form-control:focus ~ label,
.builder .form-floating > .form-control:not(:placeholder-shown) ~ label,
.builder .form-floating > .form-control-plaintext ~ label,
.builder .form-floating > .form-select ~ label {
  color: white;
}
.builder .form-floating > .form-control:focus ~ label::after,
.builder .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.builder .form-floating > .form-control-plaintext ~ label::after,
.builder .form-floating > .form-select ~ label::after {
  background-color: var(--bs-primary);
}
.builder .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 0.7rem;
}
.builder .form-floating > .form-control,
.builder .form-floating > .form-control-plaintext,
.builder .form-floating > .form-select {
  height: calc(2rem + calc(var(--bs-border-width) * 2));
  min-height: calc(2rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
  font-size: 0.8rem;
}
.builder .form-floating > .form-control:focus,
.builder .form-floating > .form-control:not(:placeholder-shown),
.builder .form-floating > .form-control-plaintext:focus,
.builder .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 0.9rem;
  padding-bottom: 0.625rem;
}
.builder .form-floating > .form-select {
  padding-top: 0.3rem;
  padding-bottom: 0rem;
}

.builder .form-floating > .form-control:focus ~ label,
.builder .form-floating > .form-control:not(:placeholder-shown) ~ label,
.builder .form-floating > .form-control-plaintext ~ label,
.builder .form-floating > .form-select ~ label {
  transform: scale(0.75) translateY(-1rem) translateX(0.15rem);
}

.builder .form-select {
  font-size: 0.8rem;
}
label.form-check-label {
  font-size: 0.8rem;
}
.col-3 {
  flex: 1 0 auto;
}
div#textButton {
  --bs-border-width: 1px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  text-align: left;
}
div#textButton::after {
  border-top: 0em solid;
  min-height: 24px;
}
</style>
