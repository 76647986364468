const toasts = {
    state: () => ({
            toasts:[]
            
    }),
    actions:{
        showToast({ commit }, payload) {

            let toastData = payload
            let toastID = new Date() / 1
            toastData.id = toastID

            commit("addToast", toastData)
        },
    },
    mutations:{
        addToast (state, payload) {
            let toastData = payload
            state.toasts.push(toastData)
        },
        removeToast (state, payload){
            let toastId = payload
            state.toasts = state.toasts.filter((elem) => {return elem.id != toastId})
        }
    },
    getters:{
        getToasts(state){
            return state.toasts
        }
    }


}

export default toasts