<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref, onMounted } from "vue";
import { props as propsModel } from "./models/props.js";
import bootstrap from "bootstrap/dist/js/bootstrap.js";

const props = defineProps(propsModel);

onMounted(() => {
  // Initializing ToolTips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList];
  tooltipList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
})


const inputValue = ref(props.value);
const emit = defineEmits(["pushData"]);
</script>

<template>
  <div class="mb-3" v-show="!props.hide">
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-html="true"
      :data-bs-toggle=" tooltip === '' ? '' : 'tooltip'"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <textarea
      :placeholder="placeholder"
      :required="required"
      :disabled="readonly"
      :type="type"
      class="form-control"
      :id="id"
      aria-describedby="emailHelp"
      v-model="inputValue"
      :value="inputValue"
      :maxlength="maxlength"
      :change="emit('pushData', {id: id, value: inputValue})"
    ></textarea>
    <p class="float-end fst-italic" :style="inputValue && inputValue.length == props.maxlength ? 'color: red':''"> {{ inputValue ? inputValue.length : 0 }} / {{ props.maxlength }} </p>
    <div id class="form-text">{{ props.description }}</div>
  </div>
</template>
