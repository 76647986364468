import axios from "axios";

const user = {
    state: () => ({
        user: {}

    }),
    actions: {
        getUser ({ state })  {
            const token = localStorage.getItem("token");
            const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;
            const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
            };
            if( Object.keys(state.user).length ){
                return state.user
            }
            else{
            return axios
                .get(`${base_url}/users/get-current-user`, {
                    headers
                })
                .then(resp => {
                    state.user = resp.data
                    return resp.data
                });
            }
        }
    },
    mutations: {},
    getters: {}
}
export default user