import { createStore } from 'vuex'
import toasts from './modules/toasts'
import user from './modules/user'

const store = createStore({
    modules:{
        toasts,
        user
    }
})

export default store