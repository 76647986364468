<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref } from "vue";
const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  // value: {
  //   type: String,
  //   default: ""
  // },
  id: {
    type: String,
    default: null
  },
  name: {
    type: String,
    default: ""
  },
  options: {
    type: Object,
    default() {
      return {};
    }
  },
  type: {
    type: String,
    default: "radio"
  },
  description: {
    type: String,
    default: ""
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: String,
    default: ""
  }
});

const inputValue = ref(props.options.filter(e=>e.checked === true)[0].value);
const emit = defineEmits(["pushData"]);
function changeButton(event){
  emit('pushData', {id: props.id, value: event.target.value})
}
</script>
<template>
  <div class="mb-3">
    <div class="form-check" v-for="item in props.options" :key="item.value">
      <input
        :name="id"
        :checked="item.checked"
        :required="required"
        :readonly="readonly"
        :type="type"
        class="form-check-input"
        :id="item.value"
        v-model="inputValue"
        :value="item.value"
        @click="changeButton"
      >
      <label :for="id" class="form-check-label"> {{ item.label }} </label><span class="red-text" v-if="required">*</span>
      <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-toggle=" tooltip === '' ? '' : tooltip"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
      <div class="form-text">{{ item.description }}</div>
    </div>
    <div class="form-text">{{ props.description }}</div>
  </div>
</template>
