<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref, onBeforeMount, computed } from "vue";
import { props as propsModel } from "./models/props.js";

const props = defineProps(propsModel);
const emit = defineEmits(["pushData"]);

// const inputValue = ref(props.value);
const data = ref({});

const swiper = ref(null); // $ref in template
const swiperComputed = computed(() => {
  if (swiper.value) {
    return (
      // "color: transparent; pointer-events: none; z-index: -1; position: absolute; left: 0; width: 97%; background: transparent;height: " +
      "color: transparent; pointer-events: none; z-index: -1 ;border: 0px !important; position: absolute; left: 0; width: 97%; background: transparent;height: " +
      swiper.value.offsetHeight +
      "px"
    );
  }
  return "height: 0px";
});
const checkedElements = computed(() => {
  return Object.values(data.value).includes(true) ? true : null;
});

onBeforeMount(() => {
  props.options.forEach(e => {
    data.value[e.value] = false;
  });
});

function pushData() {
  let val = "";
  Object.keys(data.value).forEach(key => {
    if (data.value[key]) {
      val +=
        props.options.filter(opt => {
          return opt.value == key;
        })[0].text + ";";
    }
  });

  emit("pushData", {
    id: props.id,
    // value: JSON.parse(JSON.stringify(data.value))
    value: val
  });
}
</script>

<template>
  <div class="warp-container" ref="swiper">
    <input
      :required="required"
      type="text"
      class="form-control mx-2 p-0 object-fit-contain border rounded"
      :style="swiperComputed"
      v-model="checkedElements"
    >

    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-html="true"
      :data-bs-toggle=" tooltip === '' ? '' : 'tooltip'"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <div class="form-check mb-3">
      <div class="row row-cols-2">
        <div class="col" v-for="checkbox in props.options" :key="checkbox">
          <input
            :type="type"
            class="form-check-input"
            :required="required && !checkedElements ? true : false"
            :id="id+checkbox.value"
            v-model="data[checkbox.value]"
            :value="data[checkbox.value]"
            @change="pushData()"
          >
          <label :for="id+checkbox.value" class="form-check-label">{{ checkbox.text }}</label>
        </div>
      </div>
    </div>

    <div class="form-text">{{ props.description }}</div>
  </div>
</template>
