<script setup>
import { defineProps, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from "vuex";
const store = useStore()
const props = defineProps({
  toast: {
    type: Object,
    default() {
      return {};
    }
  }
  
});
const width = ref(100)
const interval = ref()
const colorClass = (color) => {
  let toastClass = '';
  switch(color){
    case 'warning':
      toastClass =  'toast text-bg-warning'
      break
    case 'success':
      toastClass =  'toast text-bg-success'
      break
    case 'error':
      toastClass =  'toast text-bg-danger'
      break
    case 'info':
      toastClass =  'toast text-bg-primary'
      break
    default: 
      toastClass =  'toast'
  }
  return toastClass
}

onMounted(()=>{
  function timer(){
    store.commit("removeToast", props.toast.id)
  }
  setTimeout(timer, props.toast.timer)

  function setTimer(){
  width.value = width.value - (100 * 100 / props.toast.timer)
  if(width.value === 0){
    store.commit('removeToast', props.toast.id)
  }
}
interval.value = setInterval(setTimer, 100)
})
onUnmounted(()=>{
  clearInterval(interval.value)
})

function forceClose(){
  store.commit('removeToast', props.toast.id)
}

</script>

<template>
  <!-- <div class="toastContainer"> -->
    <div :class="colorClass(props.toast.color)" role="alert" aria-live="assertive" aria-atomic="true" style="display: block">
      
      <div class="toast-header">
        <div
        class="progress "
        role="progressbar"
        aria-label="1px high"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar" :style="{ 'width': width + '%' }"></div>
      </div>
        <img src class="rounded me-2" alt>
        <strong class="me-auto">{{ props.toast.title }}</strong>
        <small class="text-body-secondary">Только что</small>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click.prevent="forceClose"
        ></button>
      </div>
      <div class="toast-body">{{ props.toast.message }}</div>
    </div>
  <!-- </div> -->
</template>
<style>
.toast{
  position: relative;
  z-index:999;
}
.toastContainer{
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.progress {
   height: 1px;
    position: absolute;
    top: -1px;
    left: 5px;
    width: 97%;
    background-color: rgba(0, 0, 0, 0.175);
}



</style>