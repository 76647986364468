<script setup>
import { defineProps, defineEmits, ref, onMounted, reactive, watch, computed } from "vue";
// import { defineProps, defineEmits, ref, onMounted } from "vue";
import axios from "axios";
import { props as propsModel } from "./models/props.js";
import { sortBy, filterBy } from "./functions.js";

//Search part
import searchBox from "./utils/SearchWebPart";


const props = defineProps(propsModel);

const emit = defineEmits(["pushData"]);
const search = ref("");
const data = reactive({});
const filtredData = ref(data);
const token = localStorage.getItem("token");
const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`
};
const user = reactive({});

const selected = reactive([]);

const swiper2 = ref(null); // $ref in template
const swiperComputed = ref("")

computed(() => {
  console.log("-----in computed------")
  console.log(swiper2.value)
  return swiper2
//   if (swiper2.value) {
//      console.log("WATCH swiper2.value.$el.clientHeight")
//   console.log(swiper2.value.offsetHeight)
//     return (
//       // "color: transparent; pointer-events: none; z-index: -1; position: absolute; left: 0; width: 97%; background: transparent;height: " +
//       "position: absolute; z-index: -1; color: white;height: " +
//       (swiper2.value.offsetHeight + 20) +
//       "px"
//     );
//   }
//   return "height: 0px";
});

watch(data, () => {
  if ("defaultValue" in props.options) {
    autoSelectElemet();
  }
});
// const buttonText = ref("");
watch(props, (oldValue, newValue) => {
   if(props.hide && !props.preserveValue){
    emit('pushData', {id: props.id, value: ""})
    selected.splice(0,selected.length)
  }
  if (newValue != "" && newValue != null && newValue != undefined) {
    console.log("in watch");
    console.log(newValue.masterComponentValue);
    // selected.splice(0);
    if (
      newValue.masterComponentValue === "" ||
      newValue.masterComponentValue === null ||
      newValue.masterComponentValue === undefined
    ) {
      data.value = {};
      selected.value = [];
    } else {
      fetchData(newValue.masterComponentValue);
    }
  }
});

watch(user, (oldval, newval) => {
  if ("id" in newval.value) {
    fetchData(newval.value.id);
  }
});

onMounted(() => {

  console.log("swiper2.value.$el.clientHeight")
  console.log(swiper2.value.clientHeight)
swiperComputed.value = "position: absolute; z-index: -1; color: white;height: " + swiper2.value.offsetHeight + "px"
  const searchInput = document.getElementById(props.id + "-search");
  const myCollapsible = document.getElementById(props.id + "-dd");
  if (myCollapsible) {
    myCollapsible.addEventListener("hide.bs.dropdown", () => {
      search.value = "";
    });
    myCollapsible.addEventListener("shown.bs.dropdown", () => {
      searchInput.focus();
    });
  }

  if (props.options.API.includes("${user_id}")) {
    console.log("no referComponents");
    axios
      .get(`${process.env.VUE_APP_BASE_API}/api/v1/users/get-current-user`, {
        headers
      })
      .then(resp => {
        user.value = resp.data;
      });
  } else if (props.masterComponentValue === undefined) {
    fetchData();
  } else {
    console.log("no referComponents");
  }
});

function autoSelectElemet() {

  switch(props.options.defaultValue) {
    case "last":
    selected.splice(0,selected.length)
    if(Object.keys(data.value).length !== 0){
      selected.push(data.value[data.value.length-1].text);
    }
    break
    
    case "first":
    selected.splice(0,selected.length)
    if(Object.keys(data.value).length !== 0){
      selected.push(data.value[0].text);
    }
    break
    
    case null:
    case "":
      selected.splice(0,selected.length)
    break
    
    default:
    props.options.defaultValue.split(",").forEach(elem => {
        let element = data.value.filter(e => e.value == elem)[0];
        if (element != undefined) {
          // elems.push(element)
          selected.push(element.text);
          // emit("pushData", { id: props.id, value: data.value[0].value.toString() });
        }
      });
    break
  }
  let vals = ""
if(Object.keys(data.value).length !== 0){
   vals = selected
      .map(elem => { let v = data.value.filter(d => d.text === elem)[0]
                   if(v){return v.value}
                    else{return null}
                   }
                    )
      .join(",");
}

      if(vals.split(",")[0] === ""){
        selected.splice(0,selected.length)
      }
  // selected
  //     .map(elem => data.value.filter(d => d.text === elem)[0].value)
  //     .join(",");
    emit("pushData", { id: props.id, value: vals.split(",")[0] });
}

function fetchData(masterValue) {
  let url = `${base_url}${props.options.API}`;

  if (masterValue) {
    url = url.replace(/\$\{.*\}/, masterValue);
  }

  const value = props.options.valueColumn;
  const text = props.options.textColumn;

  axios.get(url, { headers }).then(resp => {
    data.value = resp.data.map(e => {
      return { text: e[text], value: e[value] };
    });
     if (props.filter.filterBy != "" && props.filter.value != "") {
      filterBy(data, props.filter);
    }
    sortBy(data, props.sort, "text");
  });
}
function showNoData() {

  if (data.value != undefined) {
    if (data.value.length === 0 || data.value.length === undefined) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}
// Search part
function filterData(data) {
  filtredData.value = data;
}
function showSearchString() {
  if (props.searchbox) {
    if (data.value != undefined) {
      if (data.value.length != 0 && data.value.length != undefined) {
        return true;
      }
    }
  }
  return false;
}
function clearElement() {
  selected.splice(0,selected.length)
  emit("pushData", { id: props.id, value: "" });
}

function selectElement(event) {
  console.log(swiper2.value.offsetHeight)
  if (selected.includes(event.target.outerText)) {
    let index = selected.indexOf(event.target.outerText);
    selected.splice(index, 1);
  } else {
    selected.push(event.target.outerText);
  }
  if (selected.length != 0) {
    let vals = selected
      .map(elem => data.value.filter(d => d.text === elem)[0].value)
      .join(",");
    emit("pushData", { id: props.id, value: vals });
  } else {
    emit("pushData", { id: props.id, value: "" });
  }
  swiperComputed.value = "position: absolute; z-index: -1; color: white;height: " + swiper2.value.offsetHeight + "px"

}
</script>

<template>
  <div class="mb-3" v-show="!props.hide">
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      :data-bs-html="true"
      style="color:#0d6efd;"
      :data-bs-toggle=" tooltip === '' ? '' : 'tooltip'"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <div class="input-group" style="width: inherit;">
      <div class="dropdown d-grid gap-2 flex-fill" :id="id+'-dd'">
        <input
          class="form-select form-control"
          v-model="selected"
          :required="required"
          :style="swiperComputed"
        >
        <div
          ref="swiper2"
          id="textButton"
          :class="readonly ? 'btn dropdown-toggle disabled d-flex ':'btn dropdown-toggle d-flex'"
          :required="required"
          data-bs-display="static"
          type="text"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <div
            v-for="v in selected"
            :key="v"
            class="badge text-bg-primary px-2 me-1 align-items-center mb-1"
          >{{ v }}</div>
          <div v-if="selected.length===0" class="text-secondary">{{ props.placeholder }}</div>
        </div>
        <ul class="dropdown-menu container">
          <div class="row">
             <searchBox
              :data="data"
              :id="props.id"
              @pushData="filterData($event)"
              v-show="showSearchString()"
            />
            <div v-show="showNoData()">
              <p
                class="ms-3 mb-0"
                v-if="props.hasOwnProperty('masterComponentValue') && data.value !== undefined"
              >Не выбрано значение зависимого поля</p>
              <p class="ms-3 mb-0" v-else>Нет данных</p>
            </div>
          </div>
          <li>
            <hr class="dropdown-divider" v-show="!(!props.searchbox || showNoData())">
          </li>
          <div class="row">
            <div class="col" v-if="filtredData">
              <li v-for="item in filtredData" :key="item.value">
                <button
                  class="dropdown-item"
                  :class="selected.includes(item.text) ? 'dropdown-item active':'dropdown-item' "
                  @click.prevent="selectElement"
                  :value="item.value"
                >{{ item.text }}</button>
              </li>
            </div>
          </div>
        </ul>
      </div>
      <button type="button" class="btn" @click.prevent="clearElement()" v-if="clearButton">
        <i class="bi bi-eraser-fill"></i>
      </button>
    </div>
    <div id class="form-text">{{ props.description }}</div>
  </div>
</template>

<style>
div#textButton {
  --bs-border-width: 1px;
  /* border: var(--bs-border-width) solid var(--bs-border-color); */
  border: transparent;
  text-align: left;
}
div#textButton::after {
  border-top: 0em solid;
  min-height: 24px;
}

button#textButton {
  --bs-border-width: 1px;
  /* border: var(--bs-border-width) solid var(--bs-border-color); */
  border: transparent;
  text-align: left;
  border-radius: 7px;
}

button#textButton::after {
  border-top: 0em solid;
}
.tooltip {
  --bs-tooltip-max-width: auto;
}
.tooltip-inner {
  text-align: left;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.d-flex{
  flex-wrap: wrap;
}
</style>
