<script setup>
import { ref, onBeforeMount, defineProps } from "vue";
import moment from "moment";
import axios from "axios";

const ticket = ref(null);
const visible_not_allowed = ref(false);
const visible_not_found = ref(false);
const props = defineProps(["id"]);
onBeforeMount(() => {
  moment.locale("ru");
  const token = localStorage.getItem("token");
  const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;

  axios
    .get(`${base_url}/tickets/ticket/${props.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    .then(resp => {
      ticket.value = resp.data;
      console.log(ticket.value.full_content);
    })
    .catch(e => {
      console.log("error from tickets");
      console.log(e);
      visible_not_found.value = true;
      visible_not_allowed.value = true;
    });
});

</script>

<template>
  <div class="card" style="width: 60rem;border: transparent;background: rgba(255,255,255, 0.7)">
    <div class="card-body">
      <ul class="list-group list-group-flush" v-if="ticket">
        <li class="list-group-item">
          <div class="row">
            <div class="col-3">
              <b>Номер обращения</b>
            </div>
            <div class="col-9">{{ticket.id}}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-3">
              <b>Инициатор</b>
            </div>
            <div class="col-9">{{ticket.initiator.name}}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-3">
              <b>Время создания</b>
            </div>
            <div class="col-9">{{ moment(ticket.creation_timestamp).format('D.MM.YYYY h:mm:ss') }}</div>
          </div>
        </li>
        <li class="list-group-item" v-if="ticket.reacted_time">
          <div class="row">
            <div class="col-3">
              <b>Взят в работу</b>
            </div>
            <div class="col-9">{{ moment(ticket.reacted_time).format('D.MM.YYYY h:mm:ss') }}</div>
          </div>
        </li>
        <li class="list-group-item" v-if="ticket.completed_date">
          <div class="row">
            <div class="col-3">
              <b>Завершён</b>
            </div>
            <div class="col-9">{{moment(ticket.completed_date).format('D.MM.YYYY h:mm:ss')}}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-3">
              <b>Статус обращения</b>
            </div>
            <div
              class="col-9"
            >В настоящий момент обращение типа {{ ticket.category.name }} находится в статусе {{ ticket.status.name }}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-3">
              <b>Краткое описание</b>
            </div>
            <div class="col-9">{{ ticket.short_content }}</div>
          </div>
        </li>
        <li class="list-group-item" style="white-space: pre-wrap;">
          <div class="row">
            <div class="col-3">
              <b>Полное описание</b>
            </div>
            <div class="col-9">
              <!-- {{ ticket.full_content }} -->
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item b"
                  v-for="item in ticket.full_content.split('\n')"
                  :key="item"
                  v-show="item[0]"
                >
                  <div class="row" v-if="!item.includes(':')">
                    <div class="col">{{ item }}</div>
                  </div>
                  <div class="row" v-else-if="item.split(/:\s|:/)[1]">
                    <div class="col">
                      <b>{{item.split(/:\s|:/)[0].trim()}}:</b>
                    </div>
                    <div class="col">{{item.split(/:\s/u)[1]}}</div>
                  </div>
                  <div class="row" v-else-if="!item.split(/:\s|:/)[1]">
                    <div class="col">
                      <b>{{item.trim()}}</b>
                    </div>
                    <!-- <div class="col">{{item.split(/:\s|:/)[1]}}</div> -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="list-group-item" v-if="ticket.solution">
          <div class="row">
            <div class="col-3">
              <b>Решение</b>
            </div>
            <div class="col-9">
                 <ul class="list-group list-group-flush">
                <li
                  class="list-group-item m-0 p-0"
                  v-for="solution in ticket.solution.split('\n')"
                  :key="solution"
                  style="border-bottom: 1px dotted red"
                >
                  <p
                    class="text-body-secondary m-0 p-0"
                    style="white-space: pre-wrap;"
                  >{{solution}}</p>
                </li>
              </ul>
               </div>
          </div>
        </li>
        <li class="list-group-item" v-if="ticket.journals">
          <div class="row">
            <div class="col-3 b">
              <b>Журнал</b>
            </div>
            <div class="col-9">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item m-0 p-0"
                  v-for="journal in ticket.journals"
                  :key="journal"
                  style="border-bottom: 1px dotted red"
                >
                  <p
                    class="text-body-secondary m-0 p-0"
                    style="white-space: pre-wrap;"
                  >[{{ moment(ticket.journals.timestamp).format('D.MM.YYYY h:mm:ss') }}] {{'\t'}} {{journal.content}}</p>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <div
        class="alert alert-danger d-flex align-items-center "
        role="alert"
        v-if="visible_not_found"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-exclamation-circle me-2"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
          <path
            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"
          ></path>
        </svg>
        <div>Обращения с номером {{ id }} не существует</div>
      </div>
      <div
        class="alert alert-danger d-flex align-items-center "
        role="alert"
        v-if="visible_not_allowed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-exclamation-circle me-2"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
          <path
            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"
          ></path>
        </svg>
        <div>Обращение {{ id }} поднято не в рамках вашего контракта</div>
      </div>
    </div>
  </div>
</template>
