// ColumnReferenceSelect
export function filterBy(arr, filter){
    function filterCheck(){
        if(filter.filterBy in arr.value[0]){
            return true
        }
        else{
            return false
        }
    }
    if(!filterCheck()){
        let objKeys = Object.keys(arr.value[0])
        console.log("Wrong FilterBy prop. Posible options: " + objKeys.join(",") + ". Start fetchig by first Object prop - " + objKeys[0])
        filter.filterBy = objKeys[0]
    }

    
        arr.value = arr.value.filter(elem => elem[filter.filterBy].toString().includes(filter.filterValue)).map(e=>e)
      
}
export function sortBy(arr, direction, byField){
// Sort Object
if(byField){ 
    switch (direction) {
        case 'asc':
             arr.value.sort((a,b)=>{
                if (a[byField] > b[byField]){return 1}
                else if (a[byField] < b[byField]){return -1}
                else{return 0}
            })
            break;
        case 'desc':
            arr.value.sort((a,b)=>{
                if (a[byField] > b[byField]){return -1}
                else if (a[byField] < b[byField]){return 1}
                else{return 0}
            })
            break;
        default:
            break;
    }
}
    // Sort simple Object
else{
    switch (direction) {
        case 'asc':
             arr.value.sort((a,b)=>{
                if (a > b){return 1}
                else if (a < b){return -1}
                else{return 0}
            })
            break;
        case 'desc':
            arr.value.sort((a,b)=>{
                if (a > b){return -1}
                else if (a < b){return 1}
                else{return 0}
            })
            break;
        default:
            break;
    }
}
}

// ColumnMultiCheckbox
export function setDefaultValues(vendors, defaultValue){
    let defaultValuesArr = defaultValue.split(",")
    vendors.value.forEach((vendor) => {
        // if(defaultValuesArr.includes(vendor.id.toString())){
        //     vendors.value[vendor_index].category.forEach(category=>{category.selected = true})
        // }
        // else{
            vendor.category.forEach(category => {
                if(defaultValuesArr.includes(category.id.toString())){
                    category.selected = true
                }
            })
        // }
    })
    return vendors
}
