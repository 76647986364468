<script setup>
// import { defineEmits } from 'vue'
import { defineProps, defineEmits, ref, onBeforeMount, computed } from "vue";
import { props as propsModel } from "./models/props.js";
import { setDefaultValues } from "./functions.js";

import axios from "axios";

const swiper = ref(null); // $ref in template
const swiperComputed = computed(() => {
  if (swiper.value) {
    return (
      "color: transparent; pointer-events: none; z-index: -1 ;border: 0px !important; position: absolute; left: 0; width: 97%; background: transparent;height: " +
      swiper.value.offsetHeight +
      "px"
    );
  }
  return "height: 0px";
});
const checkedElements = computed(() => {
  let cat = [];
  vendors.value.forEach(v =>
    v.category.forEach(c => (c.selected === true ? cat.push(c) : ""))
  );
  if (cat.length > 0) {
    return cat;
  } else {
    return "";
  }
});

const props = defineProps(propsModel);

const emit = defineEmits(["pushData"]);
const vendors = ref([]);
const loading = ref(true);

// Axios part
const token = localStorage.getItem("token");
const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`
};

onBeforeMount(() => {
  axios
    .get(`${base_url}/users/get-current-user`, { headers })
    .then(resp => {
      let url = props.options.API.replace(/\$\{.*\}/, resp.data.id);
      return axios.get(`${base_url}${url}`, { headers });
    })
    .then(resp => {
      vendors.value = resp.data;
      const result = Promise.all(
        resp.data.map(
          async vendor =>
            await axios
              .get(`${base_url}/tickets/category?vendor=${vendor.id}`, {
                headers
              })
              .then(resp => {
                vendor.category = resp.data;
                vendor.category.forEach(
                  category => (category.selected = false)
                );
              })
        )
      );
      return result;
    })
    .then(result => {
      if (props.options.defaultValue) {
        setDefaultValues(vendors, props.options.defaultValue);
      }
      console.log(result);
      loading.value = false;
    });
});

function selectElement(vendor) {
  let group_checkbox_ = document.getElementById(
    `v-${vendors.value[vendor].id}`
  );
  let selected_ = vendors.value[vendor].category.filter(
    e => e.selected === true
  ).length;
  if (selected_ > 0 && selected_ < vendors.value[vendor].category.length) {
    if (group_checkbox_.checked !== true) {
      group_checkbox_.checked = true;
    }
    if (group_checkbox_.indeterminate !== true) {
      group_checkbox_.indeterminate = true;
    }
  } else if (selected_ === 0) {
    group_checkbox_.checked = false;
    group_checkbox_.indeterminate = false;
  } else {
    group_checkbox_.checked = true;
    group_checkbox_.indeterminate = false;
  }

  emit("pushData", {
    id: props.id,
    value: JSON.parse(JSON.stringify(vendors.value))
  });
}

function selectElementsBulk(vendor) {
  let group_checkbox_ = document.getElementById(
    `v-${vendors.value[vendor].id}`
  );
  let selected_ = vendors.value[vendor].category.filter(
    e => e.selected === true
  ).length;

  group_checkbox_.indeterminate = false;
  if (
    (selected_ > 0 && selected_ < vendors.value[vendor].category.length) ||
    selected_ === 0
  ) {
    group_checkbox_.checked = true;
    vendors.value[vendor].category.forEach(
      category => (category.selected = true)
    );
  } else {
    group_checkbox_.checked = false;
    vendors.value[vendor].category.forEach(
      category => (category.selected = false)
    );
  }
  emit("pushData", {
    id: props.id,
    value: JSON.parse(JSON.stringify(vendors.value))
  });
}
</script>
<template>
  <div v-if="!loading" class="warp-container" ref="swiper">
    <input
      :required="required"
      type="text"
      class="form-control mx-2 p-0 object-fit-contain border rounded"
      :style="swiperComputed"
      v-model="checkedElements"
    >

    <!-- <h1 class="display-6 pt-1"> -->
    <!-- {{ props.label }} -->
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      style="color:#0d6efd;"
      :data-bs-toggle=" tooltip === '' ? '' : tooltip"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <!-- </h1> -->

    <div class="form-check mb-3" v-for="(vendor, vendor_index) in vendors" :key="vendor.id">
      <div class="row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input
              :type="type"
              :required="required && !checkedElements ? true : false"
              class="form-check-input mt-2"
              @change="selectElementsBulk(vendor_index)"
              :id="'v-'+vendor.id"
            >
            <h3>{{ vendor.name }}</h3>
          </div>
        </div>
      </div>

      <div class="row row-cols-2">
        <div class="col" v-for="(category, category_index) in vendor.category" :key="category.id">
          <input
            :data-bs-toggle=" tooltip === '' ? '' : tooltip"
            data-bs-placement="top"
            :title="tooltip"
            :required="required && !checkedElements ? true : false"
            :disabled="readonly"
            v-model="vendors[vendor_index].category[category_index].selected"
            :value="vendors[vendor_index].category[category_index].selected"
            :type="type"
            class="form-check-input"
            :id="'c-'+category.id"
            @change="selectElement(vendor_index,category_index)"
          >
          <label :for="'c-'+category.id" class="form-check-label">{{ category.name }}</label>
        </div>
      </div>
    </div>
    <div class="form-text">{{ props.description }}</div>
  </div>
</template>
