<script setup>
import GroupColumn from "./ColumnMenuGroup.vue";
import { defineEmits, defineProps, ref, onMounted } from "vue";
// import json from "../../../public/category.json";

const props = defineProps({
  menuElements: {
    type: Object,
    default() {
      return {};
    }
  },
  hideEmptyCategory:{
    type: Boolean,
    default: true
  }
})
onMounted(()=>{
  filterElements()
})
const emit = defineEmits(["pushCategory"]);
const filtredElements = ref([])

function filterElements(){
  if(props.hideEmptyCategory){
    filtredElements.value = props.menuElements
  }
  else{
    filtredElements.value = props.menuElements
  }
}


function applyCategory(category) {
  emit("pushCategory", category);
}

</script>

<template>
  <div class="container-fluid shadow p-3 mb-5 rounded">
    <h4 class="display-6">Категория</h4>
    <hr style="margin-top: 0px">
    <GroupColumn
      v-for="elem in filtredElements"
      :key="elem"
      :menuElement="elem"
      :hideEmptyCategory="props.hideEmptyCategory"
      @pushCategory="applyCategory($event)"
    />
  </div>
</template>

<style>
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-link-color);
}
ul {
  list-style-type: none;
}
</style>